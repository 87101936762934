<template>
  <div class="relative min-h-screencalc-minus-nav flex flex-col" :class="templateClass">
    <LoaderMainCss/>

    <ClientOnly>
      <NavigationLHS v-if="navState === 'full'"/>
      <NavigationLHSCollapsed/>
      <PanelMessageRotator/>
      <NavigationTop/>
      <NavigationTopMobile/>
    </ClientOnly>

    <div
      class="overflow-x-hidden"
      :class="wrapperClass"
    >
      <ClientOnly>
        <main class="px-3 py-5 relative z-0 w-full mx-auto max-w-5xl min-h-screencalc-minus-nav">
          <slot/>
        </main>
        <FooterMain/>
      </ClientOnly>
    </div>

    <ClientOnly>
      <ChatDiscordMain v-if="discordChatEnabled"/>
    </ClientOnly>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUiStore } from '@/store/ui';

export default defineComponent({
  data() {
    return {
      updateTrigger: false,
    };
  },
  computed: {
    ...mapState(useUiStore, [
      'discordChatEnabled',
      'chatState',
      'navState',
      'templateClass',
    ]),

    wrapperClass() {
      const classes = [];
      if (this.navState === 'full') {
        classes.push('lg:pl-[--nav-left-width]');
      } else if (this.navState === 'mini') {
        classes.push('lg:pl-[--nav-left-mini-width]');
      }
      if (!['hidden', 'disabled',].includes(this.chatState)) {
        classes.push('xl:pr-[--chat-desktop-width]');
      }
      return classes.join(' ');
    },
  },
  mounted() {
    // For some reason the wrapper class is not updated when the navState is changed on page load. This is a workaround.
    this.updateWrapperClass();
  },
  methods: {
    updateWrapperClass() {
      this.updateTrigger = !this.updateTrigger;
    },
  },
});
</script>
